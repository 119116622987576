import axiosInstance from 'src/utils/axiosInstance'

const getUPCCustomersList = async () => {
  try {
    const response = await axiosInstance.get(`api/upc/customers`, {})
    return response.data
  } catch (error) {
    throw error.response
  }
}
const getUPCDesignsList = async (isDependent, customerId) => {
  try {
    if (isDependent) {
      const response = await axiosInstance.get(`api/upc/designs?customerId=${customerId}`, {})
      return response.data
    }

    const response = await axiosInstance.get(`api/upc/designs`, {})
    return response.data
  } catch (error) {
    throw error.response
  }
}
const getUPCStylesList = async (isDependent, customerId, designId) => {
  try {
    if (isDependent) {
      const response = await axiosInstance.get(
        `api/upc/styles?customerId=${customerId}&designId=${designId}`,
        {},
      )
      return response.data
    }
    const response = await axiosInstance.get(`api/upc/styles`, {})
    return response.data
  } catch (error) {
    throw error.response
  }
}
const getUPCColorsList = async (isDependent, customerId, designId, styleId) => {
  try {
    if (isDependent) {
      const response = await axiosInstance.get(
        `api/upc/colors?customerId=${customerId}&designId=${designId}&styleId=${styleId}`,
        {},
      )
      return response.data
    }
    const response = await axiosInstance.get(`api/upc/colors`, {})
    return response.data
  } catch (error) {
    throw error.response
  }
}
const getUPCOrdersList = async ({
  page,
  order,
  orderBy,
  limit,
  keyword,
  forBatch,
  isPaginate = true,
}) => {
  try {
    const response = await axiosInstance.get(
      `api/upc/orders${isPaginate ? '?paginate=true' : ''}`,
      {
        params: { page, order, orderBy, limit, keyword, forBatch },
      },
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const getPackingOrders = async () => {
  try {
    const response = await axiosInstance.get(
      `api/process/orders?type=screening&forDropDown=true`,
      {},
    )
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getPackingReports = async (orderIds) => {
  try {
    // https://activeapparelonline.com/api/process/report?orderIds=[393]
    const response = await axiosInstance.get(`api/process/report?orderIds=[${orderIds}]`, {})
    console.debug('pp', response.data)
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getStyleWisePackingReports = async (orderIds) => {
  try {
    // curl --location -g 'https://activeapparelonline.com/api/process/summary-style-report?orderIds=[393]'
    const response = await axiosInstance.get(
      `api/process/summary-style-report?orderIds=[${orderIds}]`,
      {},
    )
    console.debug('pp', response.data)
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getStyleWisePackingCommercialReports = async (orderIds) => {
  try {
    // curl --location -g 'https://activeapparelonline.com/api/process/summary-style-report?orderIds=[393]&forCommercial=true'
    const response = await axiosInstance.get(
      `api/process/summary-style-report?orderIds=[${orderIds}]&forCommercial=true`,
      {},
    )
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getMachineListCountryWise = async (payload) => {
  try {
    const params = Object.entries(payload)
      .filter(([key, value]) => value !== '')
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
    const response = await axiosInstance.get(`api/machine?${params}`, {})
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getBillingDetailCountries = async (countryName) => {
  try {
    const response = await axiosInstance.get(`api/billing-details-report/country`, {})
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getBillingDetailReport = async (payload) => {
  try {
    const response = await axiosInstance.get(`api/billing-details-report`, {
      params: payload,
    })
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getUPCExport = async ({ customerId, colorId, styleId, designId, orderId }) => {
  try {
    const response = await axiosInstance.get(`api/upc/export`, {
      params: { customerId, colorId, styleId, designId, orderId },
    })
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getPrintOrEmbroideryReport = async (payload) => {
  try {
    // https://activeapparelonline.com/api/process/report?type=embroidery&endDate=9/8/2024&startDate=9/7/2024&machineIds=[40,1]
    const response = await axiosInstance.get(
      `api/process/report?type=${payload?.reportMethod}&endDate=${payload?.endDate}&startDate=${payload?.startDate}&machineIds=[${payload?.machineIds}]`,
    )
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getOrdersStyles = async () => {
  try {
    const response = await axiosInstance.get(`api/sales-report/order-style?isStyle=true`)
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getOrdersColors = async () => {
  try {
    const response = await axiosInstance.get(`api/sales-report/order-style?isStyle=false`)
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getCustomersList = async () => {
  try {
    const response = await axiosInstance.get(`api/sales-report/customer-list`)
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getSalesReport = async (payload) => {
  try {
    console.debug('getSalesReport', payload)
    const response = await axiosInstance.get(
      `/api/sales-report/?${payload?.customerId !== '' ? `id=${payload?.customerId}` : ''}${
        payload?.styleId !== '' ? `&styleId=${payload?.styleId}` : ''
      }${payload?.colorId !== '' ? `&colorId=${payload?.colorId}` : ''}&startDate=${
        payload?.startDate
      }&endDate=${payload?.endDate}&dateType=${payload?.dateType}${
        payload?.status !== '' ? `&productionStatus=${payload?.status}` : ''
      }&summary=${payload?.summary}`,
    )
    console.debug('payload', payload)

    /// should add response.data
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getBatchList = async () => {
  try {
    const response = await axiosInstance.get(`api/production-report/batch-po?isBatch=true`)
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getPoList = async () => {
  try {
    const response = await axiosInstance.get(`api/production-report/batch-po`)
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getProductionReport = async (payload) => {
  console.debug('aaa', payload)
  try {
    const params = Object.entries(payload)
      .filter(([key, value]) => value !== '')
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
    const response = await axiosInstance.get(`/api/production-report/?${params}`)
    return response?.data
  } catch (error) {
    throw error.response
  }
}

const getStyleListPallet = async () => {
  try {
    const response = await axiosInstance.get(`api/pallet-report/style`)
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getColorListPallet = async () => {
  try {
    const response = await axiosInstance.get(`api/pallet-report/color`)
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getPalletListPallet = async () => {
  try {
    const response = await axiosInstance.get(`api/pallet-report/pallet`)
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getWarehouseListPallet = async () => {
  try {
    // curl --location 'https://activeapparelonline.com/api/pallet-report/locations-warehouse?isWarehouse=true'
    const response = await axiosInstance.get(
      `api/pallet-report/locations-warehouse?isWarehouse=true`,
    )
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getLocationListPallet = async () => {
  try {
    // curl --location 'https://activeapparelonline.com/api/pallet-report/locations-warehouse?isWarehouse=true'
    const response = await axiosInstance.get(`api/pallet-report/locations-warehouse`)
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getPalletReportStyleWise = async (payload) => {
  console.debug('aaa', payload)
  try {
    const params = Object.entries(payload)
      .filter(([key, value]) => value !== '')
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
    const response = await axiosInstance.get(`/api/pallet-report?${params}`)
    return response?.data
  } catch (error) {
    throw error.response
  }
}

const getPalletReportMatrixWise = async (payload) => {
  console.debug('aaa', payload)
  try {
    const params = Object.entries(payload)
      .filter(([key, value]) => value !== '')
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
    const response = await axiosInstance.get(`/api/pallet-report/matrix?${params}`)
    return response?.data
  } catch (error) {
    throw error.response
  }
}

const getPalletReportPalletWise = async (payload) => {
  console.debug('aaa', payload)
  try {
    const params = Object.entries(payload)
      .filter(([key, value]) => value !== '')
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
    const response = await axiosInstance.get(`/api/pallet-report/pallet-matrix?${params}`)
    return response?.data
  } catch (error) {
    throw error.response
  }
}

const getSalesCommissionRep = async () => {
  try {
    // curl --location 'https://activeapparelonline.com/api/commision-report/sale-reps'
    const response = await axiosInstance.get(`api/commision-report/sale-reps`)
    return response.data
  } catch (error) {
    throw error.response
  }
}

const getCommissionReport = async (payload) => {
  console.debug('aaa', payload)
  // curl --location 'https://activeapparelonline.com/api/commision-report?salesRepId=21&month=09&year=2024'

  try {
    const params = Object.entries(payload)
      .filter(([key, value]) => value !== '')
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
    const response = await axiosInstance.get(`api/commision-report/sales?${params}`)
    return response?.data
  } catch (error) {
    console.debug('error', error)
    throw error.response
  }
}

const getBillingReportData = async () => {
  try {
    // curl --location 'https://activeapparelonline.com/api/commision-report/sale-reps'
    const response = await axiosInstance.get(`api/Billing-details-report/orders`)
    return response.data
  } catch (error) {
    throw error.response
  }
}

const reportService = {
  getUPCOrdersList,
  getUPCColorsList,
  getUPCStylesList,
  getUPCDesignsList,
  getUPCCustomersList,
  getUPCExport,
  getPackingOrders,
  getPackingReports,
  getStyleWisePackingReports,
  getStyleWisePackingCommercialReports,
  getMachineListCountryWise,
  getBillingDetailCountries,
  getBillingDetailReport,
  getPrintOrEmbroideryReport,
  getOrdersStyles,
  getOrdersColors,
  getCustomersList,
  getSalesReport,
  getBatchList,
  getPoList,
  getProductionReport,
  getStyleListPallet,
  getColorListPallet,
  getPalletListPallet,
  getWarehouseListPallet,
  getLocationListPallet,
  getPalletReportStyleWise,
  getPalletReportMatrixWise,
  getPalletReportPalletWise,
  getSalesCommissionRep,
  getCommissionReport,
  getBillingReportData,
}

export default reportService
