import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import reportService from './reportService'
import { getApiError } from 'src/utils/helper'
import { handleFulfilledList } from '../masters/mastersSlice'

const initialState = {
  upcCustomers: [],
  upcDesigns: [],
  upcStyles: [],
  upcColors: [],
  upcOrders: [],
  upcExport: [],
  packingOrders: [],
  packingReports: null,
  styleWisePackingReport: null,
  styleWisePackingCommercialReports: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: '',
  data: [],
  page: 1,
  limit: 10,
  searchKeyword: '',
  isListCompleted: false,
  machineListCountryWise: [],
  billingDetailCountries: [],
  billingDetailsReport: [],
  printingOrEmbrodieryReport: [],
  orderStyles: [],
  orderColors: [],
  orderCustomerList: [],
  salesReportData: [],
  batchList: [],
  poList: [],
  productionReport: null,
  styleListPallet: [],
  colorListPallet: [],
  palletListPallet: [],
  warehouseListPallet: [],
  locationListPallet: [],
  styleWisePalletReportData: null,
  matrixWisePalletReportData: null,
  palletWisePalletReportData: null,
  salesRep: [],
  commissionReport: null,
  billingOrders: [],
}

export const fetchUPCCustomersList = createAsyncThunk(
  'roles/fetchUPCCustomersList',
  async (isDependent, { rejectWithValue }) => {
    try {
      const response = await reportService.getUPCCustomersList(isDependent)
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchUPCDesignsList = createAsyncThunk(
  'roles/fetchUPCDesignsList',
  async ({ isDependent, customerId }, { rejectWithValue }) => {
    try {
      const response = await reportService.getUPCDesignsList(isDependent, customerId)
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchUPCStylesList = createAsyncThunk(
  'roles/fetchUPCStylesList',
  async ({ isDependent, customerId, designId }, { rejectWithValue }) => {
    try {
      const response = await reportService.getUPCStylesList(isDependent, customerId, designId)
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchUPCColorsList = createAsyncThunk(
  'roles/fetchUPCColorsList',
  async ({ isDependent, customerId, designId, styleId }, { rejectWithValue }) => {
    try {
      const response = await reportService.getUPCColorsList(
        isDependent,
        customerId,
        designId,
        styleId,
      )
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchUPCOrdersList = createAsyncThunk(
  'roles/fetchUPCOrdersList',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      isFirst = false,
      order = 'DESC',
      orderBy = 'orderNo',
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await reportService.getUPCOrdersList({
        page,
        limit,
        keyword,
        isPaginate,
        isFirst,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response.data)
    }
  },
)
export const fetchPackingOrders = createAsyncThunk(
  'roles/fetchPackingOrders',
  async (_, { rejectWithValue }) => {
    try {
      const response = await reportService.getPackingOrders()
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchPackingReports = createAsyncThunk(
  'roles/fetchPackingReports',
  async (orderIds, { rejectWithValue }) => {
    try {
      const response = await reportService.getPackingReports(orderIds)
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchStyleWiseReport = createAsyncThunk(
  'roles/fetchStyleWiseReport',
  async (orderIds, { rejectWithValue }) => {
    try {
      const response = await reportService.getStyleWisePackingReports(orderIds)
      return { orders: response?.data } ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchStyleWiseCommercialReport = createAsyncThunk(
  'roles/fetchStyleWiseCommercialReport',
  async (orderIds, { rejectWithValue }) => {
    try {
      const response = await reportService.getStyleWisePackingCommercialReports(orderIds)
      return { orders: response?.data } ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchUPCExport = createAsyncThunk(
  'roles/fetchUPCExport',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await reportService.getUPCExport(payload)
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchMachineListCountryWise = createAsyncThunk(
  'roles/fetchMachineListCountryWise',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await reportService.getMachineListCountryWise(payload)
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchBillingDetailCountries = createAsyncThunk(
  'roles/fetchBillingDetailCountries',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await reportService.getBillingDetailCountries(payload)
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchBillingDetailReport = createAsyncThunk(
  'roles/fetchBillingDetailReport',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await reportService.getBillingDetailReport(payload)
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchPrintOrEmbroideryReport = createAsyncThunk(
  'roles/fetchPrintOrEmbroideryReport',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await reportService.getPrintOrEmbroideryReport(payload)
      return { orders: response?.data, sizes: [] } ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchOrdersStyles = createAsyncThunk(
  'roles/fetchOrdersStyles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await reportService.getOrdersStyles()
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchOrdersColors = createAsyncThunk(
  'roles/fetchOrdersColors',
  async (_, { rejectWithValue }) => {
    try {
      const response = await reportService.getOrdersColors()
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchOrderCustomersList = createAsyncThunk(
  'roles/fetchOrderCustomersList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await reportService.getCustomersList()
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchSalesReportData = createAsyncThunk(
  'roles/fetchSalesReportData',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await reportService.getSalesReport(payload)
      // should add reponse.data here after api
      return { orders: response?.data, sizes: [] } ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchBatchList = createAsyncThunk(
  'roles/fetchBatchList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await reportService.getBatchList()
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchPoList = createAsyncThunk('roles/fetchPoList', async (_, { rejectWithValue }) => {
  try {
    const response = await reportService.getPoList()
    return response?.data ?? []
  } catch (error) {
    console.debug('error ==> ', error)
    return rejectWithValue(error.response)
  }
})

export const fetchProductionReport = createAsyncThunk(
  'roles/fetchProductionReport',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await reportService.getProductionReport(payload)
      return { orders: response?.data, sizes: [] } ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fecthStyleListPallet = createAsyncThunk(
  'roles/fecthStyleListPallet',
  async (_, { rejectWithValue }) => {
    try {
      const response = await reportService.getStyleListPallet()
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fecthColorListPallet = createAsyncThunk(
  'roles/fecthColorListPallet',
  async (_, { rejectWithValue }) => {
    try {
      const response = await reportService.getColorListPallet()
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fecthPalletListPallet = createAsyncThunk(
  'roles/fecthPalletListPallet',
  async (_, { rejectWithValue }) => {
    try {
      const response = await reportService.getPalletListPallet()
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fecthWarehouseListPallet = createAsyncThunk(
  'roles/fecthWarehouseListPallet',
  async (_, { rejectWithValue }) => {
    try {
      const response = await reportService.getWarehouseListPallet()
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fecthLocationListPallet = createAsyncThunk(
  'roles/fecthLocationListPallet',
  async (_, { rejectWithValue }) => {
    try {
      const response = await reportService.getLocationListPallet()
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchPalletReportStyleWise = createAsyncThunk(
  'roles/fetchPalletReportStyleWise',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await reportService.getPalletReportStyleWise(payload)
      return { orders: response?.data, sizes: [] } ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchPalletReportMatrixWise = createAsyncThunk(
  'roles/fetchPalletReportMatrixWise',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await reportService.getPalletReportMatrixWise(payload)
      return { orders: response?.data, sizes: [] } ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchPalletReportPalletWise = createAsyncThunk(
  'roles/fetchPalletReportPalletWise',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await reportService.getPalletReportPalletWise(payload)
      return { orders: response?.data, sizes: [] } ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchSalesRep = createAsyncThunk(
  'roles/fetchSalesRep',
  async (_, { rejectWithValue }) => {
    try {
      const response = await reportService.getSalesCommissionRep()
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchCommissionReport = createAsyncThunk(
  'roles/fetchCommissionReport',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await reportService.getCommissionReport(payload)
      return { orders: response, sizes: [] } ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.data)
    }
  },
)

export const fetchBillingOrders = createAsyncThunk(
  'roles/fetchBillingOrders',
  async (isDependent, { rejectWithValue }) => {
    try {
      const response = await reportService.getBillingReportData(isDependent)
      return response?.data ?? []
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response)
    }
  },
)

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false
      state.isLoading = false
      state.isExportLoading = false
      state.isSuccess = false
      state.message = ''
      state.upcExport = []
      state.packingReports = null
      state.billingDetailsReport = null
      state.styleWisePackingReport = null
      state.styleWisePackingCommercialReports = null
      state.salesReportData = null
      state.productionReport = null
      state.styleWisePalletReportData = null
      state.matrixWisePalletReportData = null
      state.palletWisePalletReportData = null
      state.commissionReport = null
    },
    setIsListCompleted: (state, action) => {
      state.isListCompleted = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUPCCustomersList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUPCCustomersList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.upcCustomers = action.payload
      })
      .addCase(fetchUPCCustomersList.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchUPCDesignsList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUPCDesignsList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.upcDesigns = action.payload
      })
      .addCase(fetchUPCDesignsList.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchUPCStylesList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUPCStylesList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.upcStyles = action.payload
      })
      .addCase(fetchUPCStylesList.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchUPCColorsList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUPCColorsList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.upcColors = action.payload
      })
      .addCase(fetchUPCColorsList.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchUPCOrdersList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUPCOrdersList.fulfilled, (state, action) => {
        // state.isLoading = false
        // state.isError = false
        // state.upcOrders = action.payload
        handleFulfilledList(state, action, 'upcOrders')
      })
      .addCase(fetchUPCOrdersList.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchPackingOrders.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPackingOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.packingOrders = action.payload
      })
      .addCase(fetchPackingOrders.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchPackingReports.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPackingReports.fulfilled, (state, action) => {
        console.debug('full', action.payload)
        state.isLoading = false
        state.isError = false
        state.packingReports = action.payload
      })
      .addCase(fetchPackingReports.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchStyleWiseReport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchStyleWiseReport.fulfilled, (state, action) => {
        console.debug('full', action.payload)
        state.isLoading = false
        state.isError = false
        state.styleWisePackingReport = action.payload
      })
      .addCase(fetchStyleWiseReport.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchStyleWiseCommercialReport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchStyleWiseCommercialReport.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.styleWisePackingCommercialReports = action.payload
      })
      .addCase(fetchStyleWiseCommercialReport.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchUPCExport.pending, (state) => {
        state.isExportLoading = true
      })
      .addCase(fetchUPCExport.fulfilled, (state, action) => {
        state.isExportLoading = false
        state.isError = false
        state.upcExport = action.payload
      })
      .addCase(fetchUPCExport.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isExportLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchMachineListCountryWise.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchMachineListCountryWise.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.machineListCountryWise = action.payload
      })
      .addCase(fetchMachineListCountryWise.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchBillingDetailCountries.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchBillingDetailCountries.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.billingDetailCountries = action.payload
      })
      .addCase(fetchBillingDetailCountries.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchBillingDetailReport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchBillingDetailReport.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.billingDetailsReport = action.payload
      })
      .addCase(fetchBillingDetailReport.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchPrintOrEmbroideryReport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPrintOrEmbroideryReport.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.printingOrEmbrodieryReport = action.payload
      })
      .addCase(fetchPrintOrEmbroideryReport.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchOrdersStyles.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOrdersStyles.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.orderStyles = action.payload
      })
      .addCase(fetchOrdersStyles.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchOrdersColors.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOrdersColors.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.orderColors = action.payload
      })
      .addCase(fetchOrdersColors.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchOrderCustomersList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOrderCustomersList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.orderCustomerList = action.payload
      })
      .addCase(fetchOrderCustomersList.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchSalesReportData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSalesReportData.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        console.debug('fulfilled', action.payload)
        state.salesReportData = action.payload
      })
      .addCase(fetchSalesReportData.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      // fetchBatchList
      .addCase(fetchBatchList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchBatchList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.batchList = action.payload
      })
      .addCase(fetchBatchList.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      // fetchPoList
      .addCase(fetchPoList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPoList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.poList = action.payload
      })
      .addCase(fetchPoList.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchProductionReport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchProductionReport.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.productionReport = action.payload
      })
      .addCase(fetchProductionReport.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fecthStyleListPallet.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fecthStyleListPallet.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.styleListPallet = action.payload
      })
      .addCase(fecthStyleListPallet.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fecthColorListPallet.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fecthColorListPallet.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.colorListPallet = action.payload
      })
      .addCase(fecthColorListPallet.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fecthPalletListPallet.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fecthPalletListPallet.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.palletListPallet = action.payload
      })
      .addCase(fecthPalletListPallet.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fecthWarehouseListPallet.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fecthWarehouseListPallet.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.warehouseListPallet = action.payload
      })
      .addCase(fecthWarehouseListPallet.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fecthLocationListPallet.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fecthLocationListPallet.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.locationListPallet = action.payload
      })
      .addCase(fecthLocationListPallet.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchPalletReportStyleWise.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPalletReportStyleWise.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.styleWisePalletReportData = action.payload
      })
      .addCase(fetchPalletReportStyleWise.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchPalletReportMatrixWise.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPalletReportMatrixWise.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.matrixWisePalletReportData = action.payload
      })
      .addCase(fetchPalletReportMatrixWise.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchPalletReportPalletWise.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPalletReportPalletWise.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.palletWisePalletReportData = action.payload
      })
      .addCase(fetchPalletReportPalletWise.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchSalesRep.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSalesRep.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.salesRep = action.payload
      })
      .addCase(fetchSalesRep.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchCommissionReport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCommissionReport.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.commissionReport = action.payload
      })
      .addCase(fetchCommissionReport.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchBillingOrders.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchBillingOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.billingOrders = action.payload
      })
      .addCase(fetchBillingOrders.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
  },
})

export const { reset, setIsListCompleted } = reportSlice.actions
export default reportSlice.reducer
